<template>
  <v-col cols="12">
    <v-form ref="certificateForm">
      <base-card class="custom-card">
        <v-card-title>Certificado Digital</v-card-title>
        <v-card-text>
          <v-row>
            <div>
              <h6 class="ms-3">Vigencia del certificado</h6>
            </div>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                ref="FechaDesde"
                v-model="dataCertificate.FechaDesde"
                label="Desde"
                prepend-inner-icon="mdi-calendar"
                disabled
              />
            </v-col>

            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                ref="FechaHasta"
                v-model="dataCertificate.FechaHasta"
                label="Hasta"
                prepend-inner-icon="mdi-calendar"
                disabled
              />

            </v-col>
          </v-row>
          <v-row>
            <div>
              <h6 class="ms-3">Configuración de Alerta</h6>
            </div>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                ref="DiasAlertar"
                v-model="dataCertificate.DiasAlertar"
                label="Alertar cuando falten (Días)"
                prepend-inner-icon="mdi-calendar-clock"
                type="number"
                clearable
                :rules="formRules"
                :error-messages="errorMessages"
              />
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                ref="DiasRepetirAlerta"
                v-model="dataCertificate.DiasRepetirAlerta"
                label="Repetir alerta cada (Días)"
                prepend-inner-icon="mdi-reload"
                type="number"
                clearable
                :rules="formRules"
                :error-messages="errorMessages"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            />
            <v-col
              cols="12"
              sm="2"
            >
              <v-btn
                class="ma-2"
                style="background-color:rgb(204 33 40);color:white !important"
                @click="validateCertificateData"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-alert
              v-show="successAlert"
              type="success"
              text
            >
              {{ msgSuccessAlert }}
            </v-alert>
            <p/>
          </v-row>
          <p/>
        </v-card-text>
      </base-card>
    </v-form>
  </v-col>
</template>

<script>

export default {
  name: 'ConfigAlertsCertificate',

  data() {
    return {
      dataCertificate: {
        FechaDesde: '',
        FechaHasta: '',
        DiasAlertar: '',
        DiasRepetirAlerta: ''
      },
      errorMessages: '',
      formHasErrors: null,
      successAlert: false,
      msgSuccessAlert: 'Alerta para certificado guardada exitosamente',
      formRules: [
        (v) => !!v || 'Este campo es requerido (Solo acepta números)',
        (v) => v > 0 || 'El valor del campo debe ser mayor a cero',
      ],
    }
  },

  computed: {
    detailCertificate() {
      return this.$store.state.certificates.certificateData
    },
    certificateForm() {
      return {
        FechaDesde: this.dataCertificate.FechaDesde,
        FechaHasta: this.dataCertificate.FechaHasta,
        DiasAlertar: this.dataCertificate.DiasAlertar,
        DiasRepetirAlerta: this.dataCertificate.DiasRepetirAlerta,
      }
    },
    statusResponseCertificateDate () {
      return this.$store.state.certificates.statusResponse
    },
  },

  created () {
    this.getCertificateDate()
  },
  watch: {
    '$store.state.certificates.certificateData': {
      handler(data) {
        this.dataCertificate.FechaDesde = data.FechaDesde
        this.dataCertificate.FechaHasta = data.FechaHasta
        this.dataCertificate.DiasAlertar = data.DiasAlertar
        this.dataCertificate.DiasRepetirAlerta = data.DiasRepetirAlerta
      },
      deep: true
    },
    formHasErrors () {
      if (this.formHasErrors === false) {
        this.sendCertificateData()
      }
    },
    statusResponseCertificateDate () {
      if(this.statusResponseCertificateDate === true) {
        this.generateAlertSuccess()
        this.formHasErrors = true
        this.$store.dispatch('certificates/setStatusResponse', false)
      }
    },
  },

  methods: {
    async getCertificateDate() {
      await  this.$store.dispatch('certificates/getCertificateData')
    },
    generateAlertSuccess() {
      this.successAlert = true
      this.$store.dispatch('certificates/getCertificateData')
      this.clearForm()
      setTimeout(() => {
        this.successAlert = false
      }, 3000)
    },
    validateCertificateData() {
      this.formHasErrors = false
      Object.keys(this.certificateForm).forEach((campo) => {
        if (!this.certificateForm[campo] || this.certificateForm[campo] < 0) this.formHasErrors = true
        this.$refs[campo].validate(true)
      })
    },
    sendCertificateData() {
      this.$store.dispatch('certificates/updateCertificateData', {
        ...this.dataCertificate
      })
    },
    clearForm () {
      this.$refs.certificateForm.reset()
    },
  }
}

</script>



